'use client';

import * as Sentry from '@sentry/nextjs';
import Error from 'next/error';
import { useEffect } from 'react';

export default function GlobalError({ error }) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <>
      <Error statusCode={error.statusCode} />
    </>
  );
}
